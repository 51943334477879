import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import cpuLoadReducer from "../features/cpu/cpuLoadSlice";
import settingsReducer from "../features/settings/settingsSlice";
import watchAll from "../features/cpu/cpuLoadSaga";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    cpuLoads: cpuLoadReducer,
    settings: settingsReducer,
  },
  middleware: [...getDefaultMiddleware(), sagaMiddleware],
});

sagaMiddleware.run(watchAll);

export default store;
