import React from "react";
import { Formik, Field } from "formik";
import AutoSave from "../../shared/components/AutoSave";
import { getRefreshInterval, getThreshold, updateSettings } from "./settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import styled from "styled-components";

const StyledSettings = styled.div`
  & .MuiTextField-root {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    width: 100%;
  }
`;

const Settings = () => {
  const threshold = useSelector(getThreshold);
  const refreshInterval = useSelector(getRefreshInterval);
  const dispatch = useDispatch();

  const initialValues = {
    threshold: threshold,
    refreshInterval: refreshInterval,
  };

  const onSubmit = (values: any) => {
    dispatch(updateSettings(values));
  };

  return (
    <StyledSettings>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              label="Threshold"
              as={TextField}
              type="number"
              id="threshold"
              name="threshold"
              value={values.threshold}
              onChange={handleChange}
            />
            <Field
              label="Refresh interval"
              as={TextField}
              type="number"
              id="refreshInterval"
              name="refreshInterval"
              value={values.refreshInterval}
              onChange={handleChange}
            />
            <AutoSave />
          </form>
        )}
      </Formik>
    </StyledSettings>
  );
};

export default Settings;
