import { createSelector, createSlice } from "@reduxjs/toolkit";

export interface CpuLoad {
  recordedAt: number;
  value: number;
}

export enum TrendDirection {
  Down,
  Flat,
  Up,
}

export interface CpuLoadTrend {
  percentage: number;
  direction: TrendDirection;
}

type CpuLoadState = {
  isLoading: boolean;
  data: CpuLoad[];
  heavyCpuLoadHistory: any[];
};

export const slice = createSlice({
  name: "cpuLoads",
  initialState: {
    isLoading: false,
    data: [],
    heavyCpuLoadHistory: [],
  } as CpuLoadState,
  reducers: {
    fetchLoad: state => {
      state.isLoading = true;
    },
    fetchCpuLoadSucceeded: (state, action) => {
      state.isLoading = false;
      state.data = [
        ...state.data,
        { recordedAt: action.payload.recordedAt, value: action.payload.value },
      ];
    },
    addLoad: (state, action) => {
      state.data.push(action.payload);
    },
    addLoads: (state, action) => {
      state.data = action.payload;
    },
    fetchCpuLoadFailed: () => {},
    clearCpuLoad: state => {
      state.data = [];
    },
    calculateHeavyCpuLoad: state => {
      const cpuLoads = state.data;
      const history = state.heavyCpuLoadHistory;
      let status = false;
      let overloads: CpuLoad[] = [];
      let normalLoads: CpuLoad[] = [];

      for (const cpuLoad of cpuLoads) {
        if (cpuLoad.value > 1) {
          overloads.push(cpuLoad);
          normalLoads = [];

          if (overloads[overloads.length - 1].recordedAt - overloads[0].recordedAt > 120000) {
            status = true;
          }
        } else {
          normalLoads.push(cpuLoad);
          overloads = [];

          if (normalLoads[normalLoads.length - 1].recordedAt - normalLoads[0].recordedAt > 120000) {
            status = false;
          }
        }
      }

      const underHeavyCpuLoad =
        history.length > 0 && !history[history.length - 1].endedAt ? true : false;
      const lastCpuLoad = state.data[state.data.length - 1];

      if (!underHeavyCpuLoad && status) {
        history.push({
          startedAt: lastCpuLoad.recordedAt,
          endedAt: null,
        });
      } else if (underHeavyCpuLoad && !status) {
        const heavyLoad = history[history.length - 1];
        heavyLoad.endedAt = lastCpuLoad.recordedAt;
      }
    },
  },
});

export const {
  fetchLoad,
  fetchCpuLoadSucceeded,
  fetchCpuLoadFailed,
  calculateHeavyCpuLoad,
  clearCpuLoad,
  addLoad,
  addLoads,
} = slice.actions;

export const getCurrentLoad = (state: { cpuLoads: CpuLoadState }): CpuLoad | null => {
  return state.cpuLoads.data.length ? state.cpuLoads.data[state.cpuLoads.data.length - 1] : null;
};

export const getCpuLoads = (state: { cpuLoads: CpuLoadState }): CpuLoad[] =>
  state.cpuLoads.data.filter(l => l.recordedAt >= Date.now() - 600000);

export const getHeavyCpuLoadHistory = (state: { cpuLoads: CpuLoadState }): any[] =>
  state.cpuLoads.heavyCpuLoadHistory;

export const getLastTenMinutesCpuLoads = createSelector(getCpuLoads, (cpuLoads: CpuLoad[]) => {
  const now = Date.now() - 600000;
  return cpuLoads.filter(cpuLoad => cpuLoad.recordedAt >= now);
});

export const getCpuOverloadPercentage = createSelector(
  getLastTenMinutesCpuLoads,
  (cpuLoads: CpuLoad[]) => {
    return cpuLoads.length
      ? Math.round((cpuLoads.filter(c => c.value > 1).length * 100) / cpuLoads.length)
      : 0;
  }
);

export const getCpuLoadTrend = createSelector(
  getLastTenMinutesCpuLoads,
  getCurrentLoad,
  (cpuLoads: CpuLoad[], current: CpuLoad | null): CpuLoadTrend => {
    if (current) {
      const from: CpuLoad = cpuLoads[0];
      const to: CpuLoad = current;
      const percentage =
        from.value > 0 ? Math.round(Math.abs((from.value - to.value) / from.value) * 100) : 0;
      if (from.value < to.value) return { direction: TrendDirection.Up, percentage };
      else if (from.value > to.value) return { direction: TrendDirection.Down, percentage };
      else return { direction: TrendDirection.Flat, percentage };
    }
    return { direction: TrendDirection.Flat, percentage: 0 };
  }
);

export const underHeavyCpuLoad = createSelector(getHeavyCpuLoadHistory, (heavyCpuLoads: any[]) => {
  return heavyCpuLoads.length > 0 && !heavyCpuLoads[heavyCpuLoads.length - 1].endedAt
    ? true
    : false;
});

export default slice.reducer;
