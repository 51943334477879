import { createSlice } from "@reduxjs/toolkit";

interface SettingsState {
  threshold: number;
  refreshInterval: number;
}

const settingsSlice = createSlice({
  initialState: {
    threshold: 120000,
    refreshInterval: 10000,
  },
  name: "Settings",
  reducers: {
    updateSettings: (state: SettingsState, { payload }) => {
      state.threshold = payload.threshold;
      state.refreshInterval = payload.refreshInterval;
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export const getThreshold = ({ settings }: { settings: SettingsState }) => settings.threshold;
export const getRefreshInterval = ({ settings }: { settings: SettingsState }) =>
  settings.refreshInterval;

export default settingsSlice.reducer;
