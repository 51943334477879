import { useEffect } from "react";
import { useFormikContext } from "formik";

const AutoSave = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    const handler = setTimeout(() => {
      submitForm();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [values, submitForm]);
  return null;
};

export default AutoSave;
