import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@material-ui/core";
import LensIcon from "@material-ui/icons/Lens";
import styled from "styled-components";
import CpuHeavyLoadIndicators from "./CpuHeavyLoadIndicators";
import { useSelector } from "react-redux";
import { getHeavyCpuLoadHistory } from "./cpuLoadSlice";

const StyledCpuHeavyLoadList = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: ${props => props.theme.spacing(2)}px;
  gap: ${props => props.theme.spacing(2)}px;

  .table {
    flex: 1 0 50%;
  }
`;

const CpuHeavyLoadList = () => {
  const heavyCpuLoadHistory = useSelector(getHeavyCpuLoadHistory);
  const heavyLoadHistory = [...heavyCpuLoadHistory].reverse();

  return (
    <StyledCpuHeavyLoadList>
      <CpuHeavyLoadIndicators></CpuHeavyLoadIndicators>
      <TableContainer component={Paper} className="table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Heavy CPU load history</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {heavyLoadHistory.map((heavyLoad: any, i) => (
              <React.Fragment key={i}>
                {heavyLoad.endedAt && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Box color="success.main">
                        <LensIcon></LensIcon>
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Recovered from heavy average load
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {new Date(heavyLoad.endedAt).toLocaleString()}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Box color="error.main">
                      <LensIcon></LensIcon>
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Under heavy average load
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {new Date(heavyLoad.startedAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledCpuHeavyLoadList>
  );
};

export default CpuHeavyLoadList;
