import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CpuHeavyLoadList from "./cpu/CpuHeavyLoadList";
import { fetchLoad } from "./cpu/cpuLoadSlice";
import CpuCurrentLoad from "./cpu/CpuCurrentLoad";
import CpuLoadStatus from "./cpu/CpuLoadStatus";
import CpuOverloadPercentage from "./cpu/CpuOverloadPercentage";
import CpuLoadTimeline from "./cpu/CpuLoadTimeline";
import styled from "styled-components";
import { getRefreshInterval } from "./settings/settingsSlice";

const StyledDashboard = styled.div`
  && {
    .indicators {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      gap: ${props => props.theme.spacing(2)}px;
      margin: ${props => props.theme.spacing(2)}px 0;
    }
  }
`;

const Dashboard = () => {
  const dispatch = useDispatch();
  const refreshInterval = useSelector(getRefreshInterval);

  useEffect(() => {
    dispatch(fetchLoad());
  }, [dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(fetchLoad());
    }, refreshInterval);
    return () => clearTimeout(timer);
  }, [dispatch, refreshInterval]);

  return (
    <StyledDashboard>
      <div className="indicators">
        <CpuLoadStatus />
        <CpuCurrentLoad />
        <CpuOverloadPercentage />
      </div>
      <CpuLoadTimeline />
      <CpuHeavyLoadList />
    </StyledDashboard>
  );
};

export default Dashboard;
