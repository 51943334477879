import React from "react";
import GaugeChart from "react-gauge-chart";
import { useTheme } from "@material-ui/core/styles";
import {
  Indicator,
  IndicatorTitle,
  IndicatorFigure,
  IndicatorFooter,
} from "../../shared/components/Indicator";
import { useSelector } from "react-redux";
import { getCurrentLoad, getCpuLoadTrend, TrendDirection } from "./cpuLoadSlice";

const CpuCurrentLoad = () => {
  const currentLoad = useSelector(getCurrentLoad);
  const trend = useSelector(getCpuLoadTrend);
  const theme = useTheme();

  return (
    <Indicator data-test="current-cpu-load-indicator">
      <IndicatorTitle>Current average CPU load</IndicatorTitle>
      <IndicatorFigure>
        <GaugeChart
          id="gauge-chart3"
          colors={[theme.palette.success.main, theme.palette.error.light, theme.palette.error.main]}
          percent={currentLoad ? currentLoad.value / 2 : undefined}
          arcsLength={[0.5, 0.5, 0.2]}
          textColor={theme.palette.text.primary}
          hideText={true}
          needleColor={theme.palette.secondary.main}
          formatTextValue={(value: string) => ((parseInt(value) / 100) * 2).toString()}
        />
      </IndicatorFigure>
      {trend.direction === TrendDirection.Flat ? (
        <IndicatorFooter>
          <span style={{ color: theme.palette.text.secondary }}>Trend:</span> stable
        </IndicatorFooter>
      ) : (
        <IndicatorFooter>
          Trend: {trend.direction === TrendDirection.Up ? "increase " : "decrease "}
          {trend.percentage}%
        </IndicatorFooter>
      )}
    </Indicator>
  );
};

export default CpuCurrentLoad;
