import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import {
  Indicator,
  IndicatorTitle,
  IndicatorFigure,
  IndicatorFooter,
} from "../../shared/components/Indicator";
import Status from "../../shared/components/Status";
import { underHeavyCpuLoad } from "./cpuLoadSlice";

const CpuLoadStatus = () => {
  const isUnderHeavyCpuLoad = useSelector(underHeavyCpuLoad);
  const theme = useTheme();

  return (
    <Indicator data-test="cpu-status-indicator">
      <IndicatorTitle>System status</IndicatorTitle>
      <IndicatorFigure>
        <Status checked={!isUnderHeavyCpuLoad}></Status>
      </IndicatorFigure>
      <IndicatorFooter>
        <span style={{ color: theme.palette.text.secondary }}>Status: </span>
        {isUnderHeavyCpuLoad ? "under heavy average CPU load" : "under normal average CPU load"}
      </IndicatorFooter>
    </Indicator>
  );
};

export default CpuLoadStatus;
