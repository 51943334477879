import { useTheme } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Indicator,
  IndicatorTitle,
  IndicatorFigure,
  IndicatorFooter,
} from "../../shared/components/Indicator";
import { getCpuOverloadPercentage } from "./cpuLoadSlice";

const CpuOverloadPercentage = () => {
  const overload = useSelector(getCpuOverloadPercentage);
  const [doughnut, setDoughnut] = useState<Chart>();
  const chartRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const initializeChart = (chartRef: any) => {
      const myChartRef = chartRef.current.getContext("2d");
      var newChart = new Chart(myChartRef, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [],
              backgroundColor: [theme.palette.error.main, theme.palette.success.main],
              borderWidth: 5,
              borderColor: ["#fff"],
            },
          ],
          labels: ["Overload", "Normal use"],
        },
        options: {
          legend: { display: false },
        },
      });
      setDoughnut(newChart);
      return newChart;
    };

    if (chartRef.current === null) return;

    const newChart = doughnut ?? initializeChart(chartRef);
    if (newChart.data.datasets?.length) {
      newChart.data.datasets[0].data = [overload, 100 - overload];
      newChart.update();
    }
  }, [doughnut, overload, theme.palette.error.main, theme.palette.success.main]);

  return (
    <Indicator data-test="overload-indicator">
      <IndicatorTitle>Overload percentage</IndicatorTitle>
      <IndicatorFigure>
        <canvas ref={chartRef}></canvas>
      </IndicatorFigure>
      <IndicatorFooter>
        <span style={{ color: theme.palette.text.secondary }}>Overload:</span> {overload}%
      </IndicatorFooter>
    </Indicator>
  );
};

export default CpuOverloadPercentage;
