import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getHeavyCpuLoadHistory } from "./cpuLoadSlice";

const StyledIndicator = styled(Paper)`
  text-align: center;
  min-width: 200px;
  flex: 1;
  padding: ${props => props.theme.spacing(2)}px;
  h3 {
    margin-top: 0;
    font-weight: 400;
  }
`;

const StyledCpuHeavyLoadIndicators = styled.article`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)}px;
`;

const CpuHeavyLoadIndicators = () => {
  const heavyCpuLoadHistory = useSelector(getHeavyCpuLoadHistory);
  const heavyLoadCount = heavyCpuLoadHistory.length;
  const recoveries = heavyCpuLoadHistory.filter(l => l.endedAt).length;

  return (
    <StyledCpuHeavyLoadIndicators>
      <StyledIndicator>
        <Typography variant="h2" color="error">
          {heavyLoadCount}
        </Typography>
        <h3>HEAVY CPU LOADS</h3>
      </StyledIndicator>
      <StyledIndicator>
        <Typography variant="h2">
          <Box color="success.main">{recoveries}</Box>
        </Typography>
        <h3>RECOVERIES</h3>
      </StyledIndicator>
    </StyledCpuHeavyLoadIndicators>
  );
};

export default CpuHeavyLoadIndicators;
